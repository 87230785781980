<!--
 * @Author: 周纯 3529728815@qq.com
 * @Date: 2023-11-13 16:49:29
 * @LastEditors: 周纯 3529728815@qq.com
 * @LastEditTime: 2023-11-14 16:10:34
 * @FilePath: \zhixiaoer_website\src\views\HomeView.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="width-100-percent">
      <el-container>
        <el-header class="padding-0">
          <header-content />
        </el-header>
        <el-main class="padding-0">
          <router-view />
          <el-backtop
            target=".el-main"
            :visibility-height="100"
            :bottom="60"
            :right="20"
        /></el-main>
      </el-container>
    </div>
  </div>
</template>

<script setup name="HomeView">
import headerContent from "@/components/headerContent";
import { useRouter, useRoute } from "vue-router";
import { ref } from "vue";
import banner1 from "@/assets/images/banner1.png";
import banner2 from "@/assets/images/banner2.png";
import banner3 from "@/assets/images/banner3.png";
import banner4 from "@/assets/images/banner4.png";
import banner5 from "@/assets/images/banner5.png";
import banner6 from "@/assets/images/banner6.png";
import banner7 from "@/assets/images/banner7.png";
import banner8 from "@/assets/images/banner8.png";
import banner14 from "@/assets/images/banner14.png";
import banner15 from "@/assets/images/banner15.png";
import commonMap from "@/components/commonMap";
const router = useRouter();
const route = useRoute();

// import { useRouter } from "vue-router";
// const router = useRouter();
const form = ref({
  radio1: "我要找工作",
  name: "",
  radio2: "1",
  phone: "",
  authCode: "",
});
const legalNotice = ref(["关于我们", "法律声明", "服务条款", "权益保护"]);
const imgList = ref([
  banner1,
  banner2,
  banner3,
  banner4,
  banner5,
  banner6,
  banner7,
]);
const disabled = ref(false);
const countDown = ref(60);
const yzm = ref("发送验证码");
const imgList1 = ref([banner8]);
const handleClick = (item) => {
  router.push({
    name: "Statement",
    state: {
      title: item,
    },
  });
};
const sendVerificationCode = () => {
  disabled.value = true;
  let timer = setInterval(() => {
    if (countDown.value > 0) {
      countDown.value--;
      yzm.value = `还剩余${countDown.value}秒`;
    } else {
      clearInterval(timer);
      disabled.value = false; // 启用按钮
      countDown.value = 60; // 重置倒计时时间
      yzm.value = "发送验证码";
    }
  }, 1000);
};
// console.log(route);
// if (route.path == "/") {
//   router.push({ name: "HomePage" });
// }
</script>

<style scoped>
.el-main {
  height: calc(100vh - 60px);
}
</style>