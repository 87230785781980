import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import '@/assets/style/index.css'
const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.mount('#app')
const debounce = (fn, delay, ...value) => {
    let timer = null;
    return () => {
        let context = this;
        let args = value;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
};