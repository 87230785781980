<template>
  <div class="background-f8f8f8 color-6f6f6f border-radius-10">
    <div
      id="container"
      style="border-radius: 10px 10px 0 0; overflow: hidden; transform: translateY(0)"
    ></div>
    <div class="padding-10 display-flex justify-content-center">
      <div class="margin-right-30">
        公司地址: 青岛市城阳区流亭街道山河路702号恒大御澜国际23-102
      </div>
      <div>
        <div>客服电话:0532-55560556</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from "vue";
onMounted(async () => {
  nextTick(() => {
    createMap();
  });
});
// 创建地图
const createMap = () => {
  var center = new TMap.LatLng(36.26378, 120.42169); //设置中心点坐标
  var map = new TMap.Map("container", {
    center: center,
  });
  var markerLayer = new TMap.MultiMarker({
    id: "marker-layer",
    map: map,
  });
  let position = {
    lat: 36.26378,
    lng: 120.42169,
  };
  markerLayer.updateGeometries([
    {
      id: 1, // 点标注数据数组
      position: new TMap.LatLng(position.lat, position.lng),
    },
  ]);
  // map.on("click", (evt) => {
  //   markerLayer.updateGeometries([
  //     {
  //       id: 1, // 点标注数据数组
  //       position: evt.latLng,
  //     },
  //   ]);
  // });
};
</script>