<template>
  <div class="header-box">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      :ellipsis="false"
      @select="handleSelect"
    >
      <el-menu-item style="padding: 0 100px">
        <img style="width: 100px" :src="banner10" />
      </el-menu-item>
      <div class="flex-grow" />
      <el-menu-item index="1">首页</el-menu-item>
      <el-menu-item index="2">我要招聘</el-menu-item>
      <!-- <el-sub-menu index="2">
        <template #title>产品中心</template>
        <el-menu-item index="2-1">职小二小程序</el-menu-item>
        <el-menu-item index="2-2">一体机</el-menu-item>
        <el-menu-item index="2-3">职小二招聘版</el-menu-item>
      </el-sub-menu> -->
      <el-menu-item index="3">联系我们</el-menu-item>
      <el-menu-item index="4">隐私政策</el-menu-item>
    </el-menu>
  </div>
</template>

<script setup>
import { ref, nextTick, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import banner10 from "@/assets/images/banner10.png";
const router = useRouter();
const route = useRoute();
const activeIndex = ref("1");
const emit = defineEmits(["click"]);
onMounted(() => {});
const handleSelect = (key, keyPath) => {
  let isImg = document.getElementsByTagName("img");
  let isH2 = document.getElementsByTagName("h2");
  localStorage.setItem("isPrivacy", false);
  if (key == "1") {
    if (route.path == "/home-page") {
      nextTick(() => {
        isImg[1].scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      });
    } else {
      router.push({
        name: "HomePage",
      });
      setTimeout(() => {
        nextTick(() => {
          isImg[1].scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        });
      }, 100);
    }
  }
  if (key == "2") {
    window.open("https://company.chinalao.com/");
  }
  // if (key == "2-1" || key == "2-2") {
  //   if (route.path == "/home-page") {
  //     nextTick(() => {
  //       isImg[5].scrollIntoView({
  //         block: "start",
  //         behavior: "smooth",
  //       });
  //     });
  //   } else {
  //     router.push({
  //       name: "HomePage",
  //     });
  //     setTimeout(() => {
  //       nextTick(() => {
  //         isImg[5].scrollIntoView({
  //           block: "start",
  //           behavior: "smooth",
  //         });
  //       });
  //     }, 100);
  //   }
  // }

  // if (key == "2-3") {
  //   if (route.path == "/home-page") {
  //     nextTick(() => {
  //       isImg[6].scrollIntoView({
  //         block: "start",
  //         behavior: "smooth",
  //       });
  //     });
  //   } else {
  //     router.push({
  //       name: "HomePage",
  //     });
  //     setTimeout(() => {
  //       nextTick(() => {
  //         isImg[6].scrollIntoView({
  //           block: "start",
  //           behavior: "smooth",
  //         });
  //       });
  //     }, 100);
  //   }
  // }
  if (key == "3") {
    if (route.path == "/home-page") {
      nextTick(() => {
        isH2[0].scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      });
    } else {
      router.push({
        name: "HomePage",
      });
      setTimeout(() => {
        nextTick(() => {
          isH2[0].scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        });
      }, 100);
    }
  }
  if (key == "4") {
    router.push({
      name: "Privacy",
    });
  }
};
</script>
