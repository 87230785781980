/*
 * @Author: 周纯 3529728815@qq.com
 * @Date: 2023-11-14 16:42:46
 * @LastEditors: 周纯 3529728815@qq.com
 * @LastEditTime: 2023-11-15 08:51:16
 * @FilePath: \zhixiaoer_website\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'//首页
const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: HomeView,
        children: [
            {
                path: '/',
                name: 'HomePage',
                component: () => import('@/views/homePage/HomePage.vue'),
                meta: { title: "职小二 好工作 好生活  好未来" },
            },
            {
                path: '/statement',
                name: 'Statement',
                component: () => import('@/views/homePage/Statement.vue'),
                meta: { title: "关于我们" },
            },
            {
                path: '/privacy',
                name: 'Privacy',
                component: () => import('@/views/homePage/Privacy.vue'),
                meta: { title: "隐私政策" },
            },

            //     {
            //         path: '/statement',
            //         name: 'Statement',
            //         component: () => import('@/views/homePage/Statement.vue'),
            //         meta: { title: "首页" },
            //     },

        ]
    },
    {
        path: '/bigscreen',
        name: 'Bigscreen',
        component: () => import('@/views/bigscreen/Bigscreen.vue'),
        meta: { title: "职小二大屏" },
    },
    {
        path: '/brand-impression',
        name: 'BrandImpression',
        component: () => import('@/views/bigscreen/firstImpression/BrandImpression.vue'),
        meta: { title: "职多多&职小二品牌印象" },
    },
    {
        path: '/data-impression',
        name: 'DataImpression',
        component: () => import('@/views/bigscreen/firstImpression/DataImpression.vue'),
        meta: { title: "职多多&职小二数据印象" },
    },
    {
        path: '/bigscreen1',
        name: 'Bigscreen1',
        component: () => import('@/views/bigscreen/Bigscreen1.vue'),
        meta: { title: "111" },
    }

]
const router = createRouter({
    mode: 'history',
    history: createWebHashHistory(),//去掉#createWebHistory() createWebHashHistory
    routes
})
router.afterEach((to) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
}
)
export default router
