<template>
  <router-view />
</template>

<script setup>
import { onMounted } from "vue";
onMounted(() => {
  document.body.style.setProperty("--el-color-primary", "#FB8C00");
  document.body.style.setProperty("--el-color-primary-light-3", "#FB8C00");
  document.body.style.setProperty("--el-color-primary-dark-2", "#FB8C00");
  document.body.style.setProperty("--el-menu-active-color", "#FB8C00");
  document.body.style.setProperty("--el-menu-hover-text-color", "#FB8C00");
});
</script>


